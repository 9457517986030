import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

const {
    getPivotState,
    setPivotState,
    getAvailableBuildings,
    setAvailableBuildings,
    getAttendanceInformation,
    setAttendanceInformation,
} = createAccessors(
    'hybridspace-teamWorkPlanDashboard',
    createStore('hybridspace-teamWorkPlanDashboard', {
        pivotState: 'summary',
        availableBuildings: [] as string[],
        attendanceInformation: new Map(),
    })
);

export {
    getPivotState,
    setPivotState,
    getAvailableBuildings,
    setAvailableBuildings,
    getAttendanceInformation,
    setAttendanceInformation,
};
